import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Heading } from "@chakra-ui/react";
import { MDXProvider } from "@mdx-js/react";
import { MDXComponents } from "../components/mdx-components/mdx-components";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Card from "../components/Card";
import { css, Global } from "@emotion/react";
import { useLocation } from "@reach/router";

type Props = {
  data: {
    mdx: {
      body: string;
      frontmatter: { slug: string; title: string; index: number };
    };
  };
};

export default function Template({
  data: { mdx }, // this prop will be injected by the GraphQL query below.
}: Props) {
  const location = useLocation();

  return (
    <Layout>
      {location.pathname.startsWith("/51782") && (
        <Global
          styles={css`
            .chakra-container {
              a {
                color: #3182ce;
                text-decoration: underline;
              }

              a:hover {
                color: #2c5282;
              }
            }
          `}
        />
      )}
      <Card>
        <Heading mb={12} textAlign="center">
          {mdx.frontmatter.title}
        </Heading>
        <MDXProvider components={MDXComponents}>
          <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Card>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
      }
    }
  }
`;
